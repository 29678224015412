[
  {
      "value": "AF",
      "label": "\u0627\u0641\u063a\u0627\u0646\u0633\u062a\u0627\u0646"
  },
  {
      "value": "AX",
      "label": "\u00c5land"
  },
  {
      "value": "AL",
      "label": "Shqip\u00ebria"
  },
  {
      "value": "DZ",
      "label": "\u0627\u0644\u062c\u0632\u0627\u0626\u0631"
  },
  {
      "value": "AS",
      "label": "American Samoa"
  },
  {
      "value": "AD",
      "label": "Andorra"
  },
  {
      "value": "AO",
      "label": "Angola"
  },
  {
      "value": "AI",
      "label": "Anguilla"
  },
  {
      "value": "AQ",
      "label": "Antarctica"
  },
  {
      "value": "AG",
      "label": "Antigua and Barbuda"
  },
  {
      "value": "AR",
      "label": "Argentina"
  },
  {
      "value": "AM",
      "label": "\u0540\u0561\u0575\u0561\u057d\u057f\u0561\u0576"
  },
  {
      "value": "AW",
      "label": "Aruba"
  },
  {
      "value": "AU",
      "label": "Australia"
  },
  {
      "value": "AT",
      "label": "\u00d6sterreich"
  },
  {
      "value": "AZ",
      "label": "Az\u0259rbaycan"
  },
  {
      "value": "BS",
      "label": "Bahamas"
  },
  {
      "value": "BH",
      "label": "\u200f\u0627\u0644\u0628\u062d\u0631\u064a\u0646"
  },
  {
      "value": "BD",
      "label": "Bangladesh"
  },
  {
      "value": "BB",
      "label": "Barbados"
  },
  {
      "value": "BY",
      "label": "\u0411\u0435\u043b\u0430\u0440\u0443\u0301\u0441\u044c"
  },
  {
      "value": "BE",
      "label": "Belgi\u00eb"
  },
  {
      "value": "BZ",
      "label": "Belize"
  },
  {
      "value": "BJ",
      "label": "B\u00e9nin"
  },
  {
      "value": "BM",
      "label": "Bermuda"
  },
  {
      "value": "BT",
      "label": "\u02bcbrug-yul"
  },
  {
      "value": "BO",
      "label": "Bolivia"
  },
  {
      "value": "BQ",
      "label": "Bonaire"
  },
  {
      "value": "BA",
      "label": "Bosna i Hercegovina"
  },
  {
      "value": "BW",
      "label": "Botswana"
  },
  {
      "value": "BV",
      "label": "Bouvet\u00f8ya"
  },
  {
      "value": "BR",
      "label": "Brasil"
  },
  {
      "value": "IO",
      "label": "British Indian Ocean Territory"
  },
  {
      "value": "BN",
      "label": "Negara Brunei Darussalam"
  },
  {
      "value": "BG",
      "label": "\u0411\u044a\u043b\u0433\u0430\u0440\u0438\u044f"
  },
  {
      "value": "BF",
      "label": "Burkina Faso"
  },
  {
      "value": "BI",
      "label": "Burundi"
  },
  {
      "value": "KH",
      "label": "K\u00e2mp\u016dch\u00e9a"
  },
  {
      "value": "CM",
      "label": "Cameroon"
  },
  {
      "value": "CA",
      "label": "Canada"
  },
  {
      "value": "CV",
      "label": "Cabo Verde"
  },
  {
      "value": "KY",
      "label": "Cayman Islands"
  },
  {
      "value": "CF",
      "label": "K\u00f6d\u00f6r\u00f6s\u00ease t\u00ee B\u00eaafr\u00eeka"
  },
  {
      "value": "TD",
      "label": "Tchad"
  },
  {
      "value": "CL",
      "label": "Chile"
  },
  {
      "value": "CN",
      "label": "\u4e2d\u56fd"
  },
  {
      "value": "CX",
      "label": "Christmas Island"
  },
  {
      "value": "CC",
      "label": "Cocos (Keeling) Islands"
  },
  {
      "value": "CO",
      "label": "Colombia"
  },
  {
      "value": "KM",
      "label": "Komori"
  },
  {
      "value": "CG",
      "label": "R\u00e9publique du Congo"
  },
  {
      "value": "CD",
      "label": "R\u00e9publique d\u00e9mocratique du Congo"
  },
  {
      "value": "CK",
      "label": "Cook Islands"
  },
  {
      "value": "CR",
      "label": "Costa Rica"
  },
  {
      "value": "CI",
      "label": "C\u00f4te d'Ivoire"
  },
  {
      "value": "HR",
      "label": "Hrvatska"
  },
  {
      "value": "CU",
      "label": "Cuba"
  },
  {
      "value": "CW",
      "label": "Cura\u00e7ao"
  },
  {
      "value": "CY",
      "label": "\u039a\u03cd\u03c0\u03c1\u03bf\u03c2"
  },
  {
      "value": "CZ",
      "label": "\u010cesk\u00e1 republika"
  },
  {
      "value": "DK",
      "label": "Danmark"
  },
  {
      "value": "DJ",
      "label": "Djibouti"
  },
  {
      "value": "DM",
      "label": "Dominica"
  },
  {
      "value": "DO",
      "label": "Rep\u00fablica Dominicana"
  },
  {
      "value": "EC",
      "label": "Ecuador"
  },
  {
      "value": "EG",
      "label": "\u0645\u0635\u0631\u200e"
  },
  {
      "value": "SV",
      "label": "El Salvador"
  },
  {
      "value": "GQ",
      "label": "Guinea Ecuatorial"
  },
  {
      "value": "ER",
      "label": "\u12a4\u122d\u1275\u122b"
  },
  {
      "value": "EE",
      "label": "Eesti"
  },
  {
      "value": "ET",
      "label": "\u12a2\u1275\u12ee\u1335\u12eb"
  },
  {
      "value": "FK",
      "label": "Falkland Islands"
  },
  {
      "value": "FO",
      "label": "F\u00f8royar"
  },
  {
      "value": "FJ",
      "label": "Fiji"
  },
  {
      "value": "FI",
      "label": "Suomi"
  },
  {
      "value": "FR",
      "label": "France"
  },
  {
      "value": "GF",
      "label": "Guyane fran\u00e7aise"
  },
  {
      "value": "PF",
      "label": "Polyn\u00e9sie fran\u00e7aise"
  },
  {
      "value": "TF",
      "label": "Territoire des Terres australes et antarctiques fr"
  },
  {
      "value": "GA",
      "label": "Gabon"
  },
  {
      "value": "GM",
      "label": "Gambia"
  },
  {
      "value": "GE",
      "label": "\u10e1\u10d0\u10e5\u10d0\u10e0\u10d7\u10d5\u10d4\u10da\u10dd"
  },
  {
      "value": "DE",
      "label": "Deutschland"
  },
  {
      "value": "GH",
      "label": "Ghana"
  },
  {
      "value": "GI",
      "label": "Gibraltar"
  },
  {
      "value": "GR",
      "label": "\u0395\u03bb\u03bb\u03ac\u03b4\u03b1"
  },
  {
      "value": "GL",
      "label": "Kalaallit Nunaat"
  },
  {
      "value": "GD",
      "label": "Grenada"
  },
  {
      "value": "GP",
      "label": "Guadeloupe"
  },
  {
      "value": "GU",
      "label": "Guam"
  },
  {
      "value": "GT",
      "label": "Guatemala"
  },
  {
      "value": "GG",
      "label": "Guernsey"
  },
  {
      "value": "GN",
      "label": "Guin\u00e9e"
  },
  {
      "value": "GW",
      "label": "Guin\u00e9-Bissau"
  },
  {
      "value": "GY",
      "label": "Guyana"
  },
  {
      "value": "HT",
      "label": "Ha\u00efti"
  },
  {
      "value": "HM",
      "label": "Heard Island and McDonald Islands"
  },
  {
      "value": "VA",
      "label": "Vaticano"
  },
  {
      "value": "HN",
      "label": "Honduras"
  },
  {
      "value": "HK",
      "label": "\u9999\u6e2f"
  },
  {
      "value": "HU",
      "label": "Magyarorsz\u00e1g"
  },
  {
      "value": "IS",
      "label": "\u00cdsland"
  },
  {
      "value": "IN",
      "label": "\u092d\u093e\u0930\u0924"
  },
  {
      "value": "ID",
      "label": "Indonesia"
  },
  {
      "value": "IR",
      "label": "\u0627\u06cc\u0631\u0627\u0646"
  },
  {
      "value": "IQ",
      "label": "\u0627\u0644\u0639\u0631\u0627\u0642"
  },
  {
      "value": "IE",
      "label": "\u00c9ire"
  },
  {
      "value": "IM",
      "label": "Isle of Man"
  },
  {
      "value": "IL",
      "label": "\u05d9\u05b4\u05e9\u05b0\u05c2\u05e8\u05b8\u05d0\u05b5\u05dc"
  },
  {
      "value": "IT",
      "label": "Italia"
  },
  {
      "value": "JM",
      "label": "Jamaica"
  },
  {
      "value": "JP",
      "label": "\u65e5\u672c"
  },
  {
      "value": "JE",
      "label": "Jersey"
  },
  {
      "value": "JO",
      "label": "\u0627\u0644\u0623\u0631\u062f\u0646"
  },
  {
      "value": "KZ",
      "label": "\u049a\u0430\u0437\u0430\u049b\u0441\u0442\u0430\u043d"
  },
  {
      "value": "KE",
      "label": "Kenya"
  },
  {
      "value": "KI",
      "label": "Kiribati"
  },
  {
      "value": "KP",
      "label": "\ubd81\ud55c"
  },
  {
      "value": "KR",
      "label": "\ub300\ud55c\ubbfc\uad6d"
  },
  {
      "value": "KW",
      "label": "\u0627\u0644\u0643\u0648\u064a\u062a"
  },
  {
      "value": "KG",
      "label": "\u041a\u044b\u0440\u0433\u044b\u0437\u0441\u0442\u0430\u043d"
  },
  {
      "value": "LA",
      "label": "\u0eaa\u0e9b\u0e9b\u0ea5\u0eb2\u0ea7"
  },
  {
      "value": "LV",
      "label": "Latvija"
  },
  {
      "value": "LB",
      "label": "\u0644\u0628\u0646\u0627\u0646"
  },
  {
      "value": "LS",
      "label": "Lesotho"
  },
  {
      "value": "LR",
      "label": "Liberia"
  },
  {
      "value": "LY",
      "label": "\u200f\u0644\u064a\u0628\u064a\u0627"
  },
  {
      "value": "LI",
      "label": "Liechtenstein"
  },
  {
      "value": "LT",
      "label": "Lietuva"
  },
  {
      "value": "LU",
      "label": "Luxembourg"
  },
  {
      "value": "MO",
      "label": "\u6fb3\u9580"
  },
  {
      "value": "MK",
      "label": "\u0421\u0435\u0432\u0435\u0440\u043d\u0430 \u041c\u0430\u043a\u0435\u0434\u043e\u043d\u0438\u0458\u0430"
  },
  {
      "value": "MG",
      "label": "Madagasikara"
  },
  {
      "value": "MW",
      "label": "Malawi"
  },
  {
      "value": "MY",
      "label": "Malaysia"
  },
  {
      "value": "MV",
      "label": "Maldives"
  },
  {
      "value": "ML",
      "label": "Mali"
  },
  {
      "value": "MT",
      "label": "Malta"
  },
  {
      "value": "MH",
      "label": "M\u0327aje\u013c"
  },
  {
      "value": "MQ",
      "label": "Martinique"
  },
  {
      "value": "MR",
      "label": "\u0645\u0648\u0631\u064a\u062a\u0627\u0646\u064a\u0627"
  },
  {
      "value": "MU",
      "label": "Maurice"
  },
  {
      "value": "YT",
      "label": "Mayotte"
  },
  {
      "value": "MX",
      "label": "M\u00e9xico"
  },
  {
      "value": "FM",
      "label": "Micronesia"
  },
  {
      "value": "MD",
      "label": "Moldova"
  },
  {
      "value": "MC",
      "label": "Monaco"
  },
  {
      "value": "MN",
      "label": "\u041c\u043e\u043d\u0433\u043e\u043b \u0443\u043b\u0441"
  },
  {
      "value": "ME",
      "label": "\u0426\u0440\u043d\u0430 \u0413\u043e\u0440\u0430"
  },
  {
      "value": "MS",
      "label": "Montserrat"
  },
  {
      "value": "MA",
      "label": "\u0627\u0644\u0645\u063a\u0631\u0628"
  },
  {
      "value": "MZ",
      "label": "Mo\u00e7ambique"
  },
  {
      "value": "MM",
      "label": "\u1019\u103c\u1014\u103a\u1019\u102c"
  },
  {
      "value": "NA",
      "label": "Namibia"
  },
  {
      "value": "NR",
      "label": "Nauru"
  },
  {
      "value": "NP",
      "label": "\u0928\u092a\u0932"
  },
  {
      "value": "NL",
      "label": "Nederland"
  },
  {
      "value": "NC",
      "label": "Nouvelle-Cal\u00e9donie"
  },
  {
      "value": "NZ",
      "label": "New Zealand"
  },
  {
      "value": "NI",
      "label": "Nicaragua"
  },
  {
      "value": "NE",
      "label": "Niger"
  },
  {
      "value": "NG",
      "label": "Nigeria"
  },
  {
      "value": "NU",
      "label": "Niu\u0113"
  },
  {
      "value": "NF",
      "label": "Norfolk Island"
  },
  {
      "value": "MP",
      "label": "Northern Mariana Islands"
  },
  {
      "value": "NO",
      "label": "Norge"
  },
  {
      "value": "OM",
      "label": "\u0639\u0645\u0627\u0646"
  },
  {
      "value": "PK",
      "label": "Pakistan"
  },
  {
      "value": "PW",
      "label": "Palau"
  },
  {
      "value": "PS",
      "label": "\u0641\u0644\u0633\u0637\u064a\u0646"
  },
  {
      "value": "PA",
      "label": "Panam\u00e1"
  },
  {
      "value": "PG",
      "label": "Papua Niugini"
  },
  {
      "value": "PY",
      "label": "Paraguay"
  },
  {
      "value": "PE",
      "label": "Per\u00fa"
  },
  {
      "value": "PH",
      "label": "Pilipinas"
  },
  {
      "value": "PN",
      "label": "Pitcairn Islands"
  },
  {
      "value": "PL",
      "label": "Polska"
  },
  {
      "value": "PT",
      "label": "Portugal"
  },
  {
      "value": "PR",
      "label": "Puerto Rico"
  },
  {
      "value": "QA",
      "label": "\u0642\u0637\u0631"
  },
  {
      "value": "RE",
      "label": "La R\u00e9union"
  },
  {
      "value": "RO",
      "label": "Rom\u00e2nia"
  },
  {
      "value": "RU",
      "label": "\u0420\u043e\u0441\u0441\u0438\u044f"
  },
  {
      "value": "RW",
      "label": "Rwanda"
  },
  {
      "value": "BL",
      "label": "Saint-Barth\u00e9lemy"
  },
  {
      "value": "SH",
      "label": "Saint Helena"
  },
  {
      "value": "KN",
      "label": "Saint Kitts and Nevis"
  },
  {
      "value": "LC",
      "label": "Saint Lucia"
  },
  {
      "value": "MF",
      "label": "Saint-Martin"
  },
  {
      "value": "PM",
      "label": "Saint-Pierre-et-Miquelon"
  },
  {
      "value": "VC",
      "label": "Saint Vincent and the Grenadines"
  },
  {
      "value": "WS",
      "label": "Samoa"
  },
  {
      "value": "SM",
      "label": "San Marino"
  },
  {
      "value": "ST",
      "label": "S\u00e3o Tom\u00e9 e Pr\u00edncipe"
  },
  {
      "value": "SA",
      "label": "\u0627\u0644\u0639\u0631\u0628\u064a\u0629 \u0627\u0644\u0633\u0639\u0648\u062f\u064a\u0629"
  },
  {
      "value": "SN",
      "label": "S\u00e9n\u00e9gal"
  },
  {
      "value": "RS",
      "label": "\u0421\u0440\u0431\u0438\u0458\u0430"
  },
  {
      "value": "SC",
      "label": "Seychelles"
  },
  {
      "value": "SL",
      "label": "Sierra Leone"
  },
  {
      "value": "SG",
      "label": "Singapore"
  },
  {
      "value": "SX",
      "label": "Sint Maarten"
  },
  {
      "value": "SK",
      "label": "Slovensko"
  },
  {
      "value": "SI",
      "label": "Slovenija"
  },
  {
      "value": "SB",
      "label": "Solomon Islands"
  },
  {
      "value": "SO",
      "label": "Soomaaliya"
  },
  {
      "value": "ZA",
      "label": "South Africa"
  },
  {
      "value": "GS",
      "label": "South Georgia"
  },
  {
      "value": "SS",
      "label": "South Sudan"
  },
  {
      "value": "ES",
      "label": "Espa\u00f1a"
  },
  {
      "value": "LK",
      "label": "\u015br\u012b la\u1e43k\u0101va"
  },
  {
      "value": "SD",
      "label": "\u0627\u0644\u0633\u0648\u062f\u0627\u0646"
  },
  {
      "value": "SR",
      "label": "Suriname"
  },
  {
      "value": "SJ",
      "label": "Svalbard og Jan Mayen"
  },
  {
      "value": "SZ",
      "label": "Swaziland"
  },
  {
      "value": "SE",
      "label": "Sverige"
  },
  {
      "value": "CH",
      "label": "Schweiz"
  },
  {
      "value": "SY",
      "label": "\u0633\u0648\u0631\u064a\u0627"
  },
  {
      "value": "TW",
      "label": "\u81fa\u7063"
  },
  {
      "value": "TJ",
      "label": "\u0422\u043e\u04b7\u0438\u043a\u0438\u0441\u0442\u043e\u043d"
  },
  {
      "value": "TZ",
      "label": "Tanzania"
  },
  {
      "value": "TH",
      "label": "\u0e1b\u0e23\u0e30\u0e40\u0e17\u0e28\u0e44\u0e17\u0e22"
  },
  {
      "value": "TL",
      "label": "Timor-Leste"
  },
  {
      "value": "TG",
      "label": "Togo"
  },
  {
      "value": "TK",
      "label": "Tokelau"
  },
  {
      "value": "TO",
      "label": "Tonga"
  },
  {
      "value": "TT",
      "label": "Trinidad and Tobago"
  },
  {
      "value": "TN",
      "label": "\u062a\u0648\u0646\u0633"
  },
  {
      "value": "TR",
      "label": "T\u00fcrkiye"
  },
  {
      "value": "TM",
      "label": "T\u00fcrkmenistan"
  },
  {
      "value": "TC",
      "label": "Turks and Caicos Islands"
  },
  {
      "value": "TV",
      "label": "Tuvalu"
  },
  {
      "value": "UG",
      "label": "Uganda"
  },
  {
      "value": "UA",
      "label": "\u0423\u043a\u0440\u0430\u0457\u043d\u0430"
  },
  {
      "value": "AE",
      "label": "\u062f\u0648\u0644\u0629 \u0627\u0644\u0625\u0645\u0627\u0631\u0627\u062a \u0627\u0644\u0639\u0631\u0628\u064a\u0629 \u0627\u0644\u0645\u062a\u062d\u062f\u0629"
  },
  {
      "value": "GB",
      "label": "United Kingdom"
  },
  {
      "value": "US",
      "label": "United States"
  },
  {
      "value": "UM",
      "label": "United States Minor Outlying Islands"
  },
  {
      "value": "UY",
      "label": "Uruguay"
  },
  {
      "value": "UZ",
      "label": "O\u2018zbekiston"
  },
  {
      "value": "VU",
      "label": "Vanuatu"
  },
  {
      "value": "VE",
      "label": "Venezuela"
  },
  {
      "value": "VN",
      "label": "Vi\u1ec7t Nam"
  },
  {
      "value": "VG",
      "label": "British Virgin Islands"
  },
  {
      "value": "VI",
      "label": "United States Virgin Islands"
  },
  {
      "value": "WF",
      "label": "Wallis et Futuna"
  },
  {
      "value": "EH",
      "label": "\u0627\u0644\u0635\u062d\u0631\u0627\u0621 \u0627\u0644\u063a\u0631\u0628\u064a\u0629"
  },
  {
      "value": "YE",
      "label": "\u0627\u0644\u064a\u064e\u0645\u064e\u0646"
  },
  {
      "value": "ZM",
      "label": "Zambia"
  },
  {
      "value": "ZW",
      "label": "Zimbabwe"
  }
]
