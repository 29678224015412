var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var getDefaultStyle = function (visible) { return ({
    display: visible ? 'flex' : 'none'
}); };
var Watch = function (_a) {
    var _b = _a.height, height = _b === void 0 ? 80 : _b, _c = _a.width, width = _c === void 0 ? 80 : _c, _d = _a.radius, radius = _d === void 0 ? 48 : _d, _e = _a.color, color = _e === void 0 ? '#4fa94d' : _e, _f = _a.ariaLabel, ariaLabel = _f === void 0 ? 'watch-loading' : _f, wrapperStyle = _a.wrapperStyle, wrapperClass = _a.wrapperClass, _g = _a.visible, visible = _g === void 0 ? true : _g;
    return (React.createElement("div", { style: __assign(__assign({}, getDefaultStyle(visible)), wrapperStyle), className: wrapperClass, "data-testid": "watch-loading" },
        React.createElement("svg", { width: width, height: height, version: "1.1", id: "L2", xmlns: "http://www.w3.org/2000/svg", x: "0px", y: "0px", viewBox: "0 0 100 100", enableBackground: "new 0 0 100 100", xmlSpace: "preserve", "aria-label": ariaLabel, "data-testid": "watch-svg" },
            React.createElement("circle", { fill: "none", stroke: color, strokeWidth: "4", strokeMiterlimit: "10", cx: "50", cy: "50", r: radius }),
            React.createElement("line", { fill: "none", strokeLinecap: "round", stroke: color, strokeWidth: "4", strokeMiterlimit: "10", x1: "50", y1: "50", x2: "85", y2: "50.5" },
                React.createElement("animateTransform", { attributeName: "transform", dur: "2s", type: "rotate", from: "0 50 50", to: "360 50 50", repeatCount: "indefinite" })),
            React.createElement("line", { fill: "none", strokeLinecap: "round", stroke: color, strokeWidth: "4", strokeMiterlimit: "10", x1: "50", y1: "50", x2: "49.5", y2: "74" },
                React.createElement("animateTransform", { attributeName: "transform", dur: "15s", type: "rotate", from: "0 50 50", to: "360 50 50", repeatCount: "indefinite" })))));
};
export default Watch;
